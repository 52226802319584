import Vue from 'vue';

export default {
    async find(parametros) {
        return Vue.axios.get("catalogo/categoria_empleado/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async get(id) {
        return Vue.axios.get("catalogo/categoria_empleado/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async update(parametros) {
        return Vue.axios.put("catalogo/categoria_empleado", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async delete(id) {
        return Vue.axios.delete("catalogo/categoria_empleado/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    async store(parametros) {
        return Vue.axios.post("catalogo/categoria_empleado", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}